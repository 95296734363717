import React, { useEffect, useCallback, useRef } from "react";
import { useState } from "react";
import groovyWalkAnimation from "./printing_animation.json";
import Lottie from "lottie-react";
import { set } from "react-hook-form";
const PassbookPrintingPage = (props) => {
  const [queue, setQueue] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [printInitiated, setPrintInitiated] = useState(false);

  function generateNbsps(count) {
    if (count <= 0) {
      return "";
    }
    return "&nbsp;".repeat(count);
  }

  const handlePrint = useCallback(async () => {
    if (printInitiated) return;

    // if (queue === null || queue === undefined) {
    //   return;
    // }
    if (!queue || queue.length === 0) {
      console.error("Queue is empty or not defined.");
      return;
    }

    // Create a new window for printing
    const printWindow = window.open("", "", "width=600,height=600");
    if (!printWindow || !printWindow.document) {
      console.error(
        "Failed to open a new window for printing. Ensure pop-ups are allowed."
      );
      return;
    }
    printWindow.document.write("<html><head><title>Print</title>");
    printWindow.document.write(
      "<style>body { font-family: monospace; }</style>"
    );
    printWindow.document.write("</head><body>");
    //first 10 lines

    // for (let i = 0; i < queue.line; i++) {
    //   printWindow.document.write("<p> &nbsp</p>");
    // }

    printWindow.document.write("<p> &nbsp</p>");
    printWindow.document.write("<p> &nbsp</p>");
    printWindow.document.write("<p> &nbsp</p>");
    printWindow.document.write("<p> &nbsp</p>");
    printWindow.document.write("<p> &nbsp</p>");
    printWindow.document.write("<p> &nbsp</p>");

    for (let i = 0; i < 32; i++) {
      if ((queue[0]?.sequence_number ?? 0) % 32 === i) {
        printWindow.document.write(
          `<p>${generateNbsps(5)}${Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }).format(new Date(queue[0]?.created_at ?? ""))}${generateNbsps(
            16 -
              (Intl.DateTimeFormat("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(queue[0]?.created_at ?? "")).length ?? 0)
          )}${commaNumber(
            queue[0]?.credit_amount ?? ""
          )}           ${generateNbsps(
            // )}${queue[0]?.credit_amount ?? ""}           ${generateNbsps(
            23 - (queue[0]?.credit_amount?.length ?? 0 + 2)
          )}${commaNumber(
            queue[0]?.debit_amount ?? ""
          )}         ${generateNbsps(
            // )}${queue[0]?.debit_amount ?? ""}         ${generateNbsps(
            (queue[0]?.credit_amount?.length ?? 0) === 0
              ? 46
              : 22 - (queue[0]?.debit_amount?.length ?? 0)
          )}${commaNumber(queue[0]?.balance ?? "")}</p>`
          // )}${queue[0]?.balance ?? ""}</p>`
        );
      } else {
        printWindow.document.write("<p> &nbsp</p>");
      }
      setPrintInitiated(true);
    }
    // queue?.map((item) =>
    //   printWindow.document.write(
    //     `<p>${generateNbsps(
    //       (item?.sequence_number ?? 0) - 1
    //     )}${Intl.DateTimeFormat("en-US", {
    //       year: "numeric",
    //       month: "2-digit",
    //       day: "2-digit",
    //     }).format(new Date(item?.created_at ?? ""))}${generateNbsps(
    //       17 -
    //         (Intl.DateTimeFormat("en-US", {
    //           year: "numeric",
    //           month: "2-digit",
    //           day: "2-digit",
    //         }).format(new Date(item?.created_at ?? "")).length ?? 0)
    //     )}${commaNumber(item?.credit_amount ?? "")}
    //     )}${item?.credit_amount ?? ""}
    //       ${generateNbsps(24 - (item?.credit_amount?.length ?? 0 + 2))}${
    //       commaNumber(item?.debit_amount ?? "")
    //       item?.debit_amount ?? ""
    //     }${generateNbsps(
    //       (item?.credit_amount?.length ?? 0) === 0
    //         ? 47
    //         : 23 - (item?.debit_amount?.length ?? 0)
    //     )}${commaNumber(item?.balance ?? "")}</p>`
    //     )}${item?.balance ?? ""}</p>`
    //   )
    // });
    // );

    //withdrawal
    // printWindow.document.write(
    //   `<p>${generateNbsps(2)}${date}${generateNbsps(
    //     17 - (date.length + 2)
    //   )}${withdrawalAmount}${generateNbsps(
    //     47 - withdrawalAmount.length
    //   )}5,197,333.47</p>`
    // );
    printWindow.document.write("<p> &nbsp</p>");

    printWindow.document.write("</body></html>");
    printWindow.document.write("<p> &nbsp</p>");
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  });

  const commaNumber = (n) => {
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  // const getQueueX = async () => {
  //   try {
  //     const queryParams = new URLSearchParams(window.location.search);
  //     const id = queryParams.get("id");
  //     const token = queryParams.get("token");
  //     var res = await getQueue(id, token);
  //     setQueue(res.data);
  //     console.log(res);
  //     return;
  //   } catch (err) {
  //     console.log(err);
  //     return;
  //   }
  // };

  // const getQueueX = async () => {};

  // useEffect(() => {
  //   try {
  //     setTimeout(() => {
  //       const queryParams = new URLSearchParams(window.location.search);
  //       const data = queryParams.get("data");
  //       var parsedData = JSON.parse(data);

  //       if (typeof parsedData === "string") {
  //         parsedData = JSON.parse(parsedData);
  //       }
  //       console.log(parsedData);
  //       setQueue(parsedData);
  //     }, 2000);

  //     setTimeout(() => {
  //       setIsLoading(false);
  //     }, 2000);

  //     setTimeout(async () => {
  //       if (queue === null || queue === undefined || isLoading) {
  //         return;
  //       }
  //       handlePrint();
  //     }, 2000);
  //
  //   if (!isLoading && queue && queue.length > 0) {
  //     handlePrint();
  //   }
  // }, [isLoading, queue]);
  // setTimeout(async () => {
  //   if (queue === null || queue === undefined || isLoading) {
  //     return;
  //   }
  //   handlePrint();
  // }, 2000);

  useEffect(() => {
    try {
      const queryParams = new URLSearchParams(window.location.search);
      const data = queryParams.get("data");
      var parsedData = JSON.parse(data);

      if (typeof parsedData === "string") {
        parsedData = JSON.parse(parsedData);
      }

      console.log(parsedData);
      setQueue(parsedData);
      setIsLoading(false);

      if (parsedData && parsedData.length > 0) {
        handlePrint();
        // setTimeout(() => {
        //   handlePrint();
        // }, 2000);
      }

      return;
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      return;
    }
  }, [handlePrint]);

  return (isLoading && queue != null) || queue === undefined ? (
    <div
      style={{ alignItems: "center", height: "100vh", alignContent: "center" }}
    >
      <center>
        <h1>Loading...</h1>
      </center>
    </div>
  ) : (
    <div
      style={{ alignItems: "center", height: "100vh", alignContent: "center" }}
    >
      <center>
        <div style={{ width: 200, height: 180 }}>
          <Lottie animationData={groovyWalkAnimation} loop={true} width={80} />
        </div>
      </center>
      <center>
        <p style={{ fontSize: 24, fontWeight: 600 }}>
          Printing in progress. Please wait...
        </p>
        {/* <button
          style={{
            padding: "10px 20px",
            fontSize: 12,
            fontWeight: "bold",
            backgroundColor: "#007bff",
            color: "#fff",
            border: "none",
            borderRadius: 4,
            marginTop: 20,
            cursor: "pointer",
          }}
          onClick={handlePrint}
        >
          Print Now
        </button> */}
      </center>
    </div>
  );
};
export default PassbookPrintingPage;
