// Import necessary components from react-router-dom
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrintPage from "./pages/index.js";
import TestingPage from "./pages/testing.js";
import PassbookPrintingPage from "./pages/passbook.js";
import Loading from "./pages/loading.js";

function App() {
  return (
    // Wrap your application in the BrowserRouter component

    <BrowserRouter>
      {/* Define your routes within Routes component */}
      <Routes>
        <Route path="/" element={<PrintPage />} />
        <Route path="/testing" element={<TestingPage />} />
        <Route path="/passbook-printing" element={<PassbookPrintingPage />} />
        <Route path="/loading" element={<Loading />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
